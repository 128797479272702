footer {
    margin-bottom: 15px;
    align-self: center;
    justify-content: center;
    text-align: center;
}

a {
    color: #555;
    font-size: 14px;
}