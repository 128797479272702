* {
  box-sizing: border-box;
}

body,
html,
.App {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background-color: #E0BFB8;
}