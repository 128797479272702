/* SignIn.css */

.main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.signin-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    width: 100%;
    align-items: center;
}

.signin-container .search-bar {
    align-self: center;
    width: 760px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 50px;
    flex-grow: 1;
    background-color: #fff;
    max-height: 62px;
    margin-bottom: 30px;
}

.typewriter-text {
    font-size: 2rem;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
}

.typing {
    display: inline-block;
    border-right: .15em solid orange;
    animation: blink-caret .75s step-end infinite;
}

.become-a-partner {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    position: relative;
    align-self: self-end;
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange;
    }
}

.signin-container .try-now {
    align-self: center;
    background-color: #BC999D;
    padding: 10px 20px;
    font-size: 1.5rem;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 50px;
}

.or-option {
    align-self: center;
}

.signin-container .sign-in {
    align-self: center;
    background-color: #9F2B68;
    padding: 10px 20px;
    font-size: 1.5rem;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 50px;
    margin-bottom: 20px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .signin-container {
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

    .signin-container .search-bar {
        width: 100%;
        max-height: 42px;
    }

    .typewriter-text {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        font-size: 1rem;
    }

    .signin-container .try-now {
        font-size: 1rem;
    }

    .signin-container .sign-in {
        font-size: 1rem;
    }
}