header {
    display: flex;
    padding: 20px;
    background-color: #FFF5EE;
    position: fixed;
    width: 100%;
    z-index: 1000;
    justify-content: space-between;
}

.logo {
    height: 40px;
}

.left-nav {
    justify-content: left;
}

.right-nav {
    align-items: flex-end;
    justify-content: right;
}

.header-link {
    text-decoration: none;
    margin: 10px;
    color: #000;
    font-weight: bold;
}

.header-button {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;
}

.header-button:hover {
    background-color: transparent;
}

header .sign-out {
    background-color: #9F2B68;
    margin-left: 10px;
}

header .sign-in {
    background-color: #9F2B68;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .logo {
        display: none;
    }
}