.favourites-container {
    padding: 20px;
    text-align: center;
    height: 100vh;
}

.favourites-container h1 {
    margin-bottom: 20px;
}

.favourites-container .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
    margin: 0 auto;
}

.grid-item-link {
    text-decoration: none;
    color: inherit;
}

.favourites-container .grid-item {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    background-color: white;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    /* Adjust width to 100% to take full column space */
    max-width: 200px;
    /* Set max-width to 200px to prevent overflow */
    height: 350px;
}

.grid-item:hover {
    transform: scale(1.05);
}

.item-image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
}

.favourites-container .favourite-button {
    position: absolute;
    bottom: 100px;
    right: 10px;
    background: rgba(159, 43, 104, 0.5);
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1;
}

.favourite-button.favourited {
    color: red;
}

.favourites-container .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}