.search-page {
    display: flex;
    height: 100vh;
}

.left-panel {
    margin-top: 80px;
    width: 300px;
    padding: 20px;
    background-color: #FFF5EE;
    border-right: 1px solid #ccc;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
}

.left-panel.open {
    transform: translateX(0);
}

.close-panel {
    display: none;
}

.main-content {
    margin-left: 300px;
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-button {
    background-color: #9F2B68;
    margin: 0px 10px;
}

.search-button:hover {
    background-color: #7a1d4e;
}

.upload-button {
    background-color: #9F2B68;
    margin: 0px 10px;
    width: 140px;
    min-width: 140px;
}

.upload-button:hover {
    background-color: #7a1d4e;
}

.button-divider {
    margin: 0px;
    align-self: center;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
}

.hamburger-menu .bar {
    width: 25px;
    height: 3px;
    background-color: #9F2B68;
    margin: 4px 0;
    transition: 0.4s;
}

.main-content .search-bar-container {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
}

.search-bar-container .search-section {
    display: flex;
    align-items: center;
    padding: 0px;
    width: 90%;
}

input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
    background-color: #f1f1f1;
}

input[type="text"]:focus {
    outline: none;
}

button {
    padding: 10px 20px;
    background-color: #007aff;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

button:hover {
    background-color: #005bb5;
}

.filter-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
}

.slider-box,
.fabrics-box {
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.fabrics-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
}

.fabric-tag {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
    background-color: #f1f1f1;
    transition: background-color 0.3s, color 0.3s;
    font-size: 12px;
}

.fabric-tag.selected {
    background-color: #9F2B68;
    color: white;
}

.fabric-tag:hover {
    background-color: #ccc;
}

.results-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    margin-bottom: 50px;
}

.grid-item-link {
    text-decoration: none;
    color: inherit;
}

.grid-item {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    background-color: white;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 200px;
    height: 350px;
}

.grid-item:hover {
    transform: scale(1.05);
}

.item-image {
    width: 100%;
    height: 243px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
}

.item-title {
    margin: 10px 0;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.item-price-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.item-brand,
.item-price,
.item-regular-price {
    margin: 5px 0;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.item-regular-price {
    text-decoration: line-through;
    color: #777;
    padding-right: 10px;
}

.favourite-button {
    position: absolute;
    bottom: 100px;
    right: 10px;
    background: rgba(159, 43, 104, 0.5);
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1;
    color: #000;
}

.favourite-button:hover {
    background-color: #f1f1f1;
}

.favourite-button.favourited {
    color: red;
}

.search-page .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {

    .search-bar-container {
        flex-direction: column;
        margin-top: 20px;
        align-items: center;
    }

    .button-divider {
        margin: 10px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        row-gap: 20px;
        column-gap: 5px;
        width: 100%;
        margin-bottom: 50px;
    }

    .grid-item {
        max-width: 170px;
    }

    .left-panel {
        position: absolute;
        transform: translateX(-100%);
        height: 100%;
        z-index: 1000;
    }

    .left-panel.open {
        transform: translateX(0);
        position: fixed;
    }

    .close-panel {
        display: block;
        margin-bottom: 20px;
        background-color: #007aff;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .main-content {
        margin-left: 0;
    }

    .hamburger-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 1001;
    }

    .search-button,
    .upload-button {
        margin: 0px 5px;
    }
}